.gif {
  box-sizing: border-box;
  display: inline-block;
  width: 25%;
}

.gif img {
  max-width: 95%;
  display:block;
  margin:auto;
}

.gifs {
  display: flex;
  flex-wrap: wrap;
}

.search {
  width: 100%;
  text-align: center;
  position: relative;
}

.listing {
  width: 900px;
  padding: 3rem 5rem 0;
  margin: 0 auto;
}

#loadMore {
  display:none;
  width: 100%;
  text-align: center;
  position: relative;
}

.MuiTextField-root {
  margin-bottom: unset !important;
  margin-right: 16px !important;
}

#button {
  position: absolute;
  bottom: 0;
}